import React from "react";
// import './nav_bar.css'
export default function NavBar({ isTr, setIsTr }) {
  var socialNetworks = [
    { name: "twitter", url: "https://twitter.com/timurturbil" },
    { name: "linkedin", url: "https://www.linkedin.com/in/timurturbil/" },
    { name: "github", url: "https://github.com/timurturbil" },
    {
      name: "stackOverFlow",
      url: "https://stackoverflow.com/users/14141494/timur-turbil",
    },
  ];
  const selectedSocialNetwork = (value) =>
    socialNetworks.filter((network) => network.name === value)[0].url;
  return (
    <div className="responsive-nav">
      <i className="fa fa-bars" id="menu-toggle"></i>
      <div id="menu" className="menu">
        <i className="fa fa-times" id="menu-close"></i>
        <div className="container">
          <div style={{ marginTop: "10px" }}>
            <button onClick={() => setIsTr(true)}>TR</button>
            <button onClick={() => setIsTr(false)}>EN</button>
          </div>
          <div className="image">
            <a href="#">
              <img src="assets/images/my_new_foto.jpeg" alt="" />
            </a>
          </div>
          <div className="author-content">
            <h4>Timur Turbil</h4>
            <span>{isTr ? "Yazılım Geliştirici" : "Software Developer"}</span>
          </div>
          <nav className="main-nav" role="navigation">
            <ul className="main-menu">
              <li>
                <a href="#section1">{isTr ? "Hakkımda" : "About Me"}</a>
              </li>
              <li>
                <a href="#section2">
                  {isTr ? "İyi olduğum yetkinlikler" : "What I’m good at"}
                </a>
              </li>
              <li>
                <a href="#section3">{isTr ? "Projelerim" : "My Work"}</a>
              </li>
              <li>
                <a href="#section4">{isTr ? "Bloglarım" : "Blogs"}</a>
              </li>
              <li>
                <a href="#section5">
                  {isTr ? "Benimle İletişime Geç" : "Contact Me"}
                </a>
              </li>
            </ul>
          </nav>
          <div className="social-network">
            <ul className="soial-icons">
              <li>
                <a href={selectedSocialNetwork("twitter")}>
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href={selectedSocialNetwork("linkedin")}>
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a href={selectedSocialNetwork("github")}>
                  <i className="fa fa-github"></i>
                </a>
              </li>
              <li>
                <a href={selectedSocialNetwork("stackOverFlow")}>
                  <i className="fa fa-stack-overflow"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
