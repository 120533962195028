import axios from 'axios';
import { url } from '../utils/url';

export async function getWork() {
    const response = await axios.get(url + "portfolio");
    return response.data;
}

export async function addWork(type, title_t, title_e, image, work_url, aboutProject_t, aboutProject_e) {
    const portfolio = {
        type: type,
        title_t: title_t,
        title_e: title_e,
        image: image,
        url: work_url,
        aboutProject_t: aboutProject_t,
        aboutProject_e: aboutProject_e
    };
    const response = await axios.post(url + "portfolio", portfolio);
    console.log(response.data);
}
export async function updateWork(id, type, title_t, title_e, image, url, aboutProject_t, aboutProject_e) {
    const portfolio = {
        type: type,
        title_t: title_t,
        title_e: title_e,
        image: image,
        url: url,
        aboutProject_t: aboutProject_t,
        aboutProject_e: aboutProject_e
    };
    const response = await axios.put(url + "portfolio" + `/${id}`, portfolio);
    console.log(response.data);
}
export async function deleteWork(id) {
    const response = await axios.delete(url + "portfolio" + `/${id}`);
    console.log(response.data);
}