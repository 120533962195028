import React, { useEffect, useState } from 'react'
import { getBlog, addBlog, updateBlog, deleteBlog } from '../../services/blogs';

export default function Blog() {
    const [blogs, setBlogs] = useState([])
    const [title_t, setTitle_t] = useState("")
    const [title_e, setTitle_e] = useState("")
    const [description_t, setDescription_t] = useState("")
    const [description_e, setDescription_e] = useState("")
    const [image, setImage] = useState("")
    const [url, setUrl] = useState("")
    useEffect(() => {
        getBlog().then(res => {
            setBlogs(res)
            console.log(res)
        })
    }, [])
    function addBlogFunction() {
        addBlog(title_t, title_e, description_t, description_e, image + "||" + url).then(response => {
            getBlog().then(res => {
                setBlogs(res)
            })
        })
    }

    function deleteBlogFunction(id) {
        deleteBlog(id).then(response => {
            getBlog().then(res => {
                setBlogs(res)
            })
        })
    }
    return (
        <div>
            {!localStorage.getItem("isAuth") ? "not logged in" : <div>
                <div>Blog</div>
                <hr className="solid" />
                <input type="text" placeholder='title_t' onChange={(e) => setTitle_t(e.target.value)} />
                <input type="text" placeholder='title_e' onChange={(e) => setTitle_e(e.target.value)} />
                <input type="text" placeholder='description_t' onChange={(e) => setDescription_t(e.target.value)} />
                <input type="text" placeholder='description_e' onChange={(e) => setDescription_e(e.target.value)} />
                <input type="text" placeholder='image' onChange={(e) => setImage(e.target.value)} />
                <input type="text" placeholder='url' onChange={(e) => setUrl(e.target.value)} />
                <button onClick={() => addBlogFunction()}>Add</button>
                <hr className="solid" />
                {blogs.length > 0 ?
                    blogs.map((item, index) => {
                        var blog_image = item.image.split("||")[0];
                        var blog_url = item.image.split("||")[1];
                        return (<div key={index}>
                            <div>text_t : {item.title_t}</div>
                            <div>text_e : {item.title_e}</div>
                            <div>description_t : {item.description_t}</div>
                            <div>description_e : {item.description_e}</div>
                            <div>image : <img style={{width: "50%"}} src={blog_image} alt="no images" /></div>
                            <div>url : {blog_url}</div>
                            <button onClick={() => deleteBlogFunction(item._id)}>Delete</button>
                            <hr className="solid" />
                        </div>)
                    })
                    : <div>No data</div>}
            </div>}
        </div>
    )
}
