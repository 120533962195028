import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function Auth() {
    const [password, setPassword] = useState("");
    let navigate = useNavigate();
    return (
        <div>
            <div>
                <input type="password" onChange={(e) => setPassword(e.target.value)} />
                <button onClick={() => {
                    if (password === "dort4444") {
                        localStorage.setItem("isAuth", true);
                        navigate("../admin", { replace: true });
                    }
                }}>click</button>
            </div>
        </div>
    )
}