import React, { useEffect, useState } from 'react'
import { getAboutMe } from '../services/about_me'

export default function AboutMe({ isTr }) {
    const [aboutMe, setAboutMe] = useState('')
    useEffect(() => {
        getAboutMe().then(res => {
            setAboutMe(res[0])
        })
    }, [])

    return (
        <section className="section about-me" data-section="section1">
            <div className="container">
                <div className="section-heading">
                    <h2>{isTr ? "Hakkımda" : "About Me"}</h2>
                    <div className="line-dec"></div>
                    <span
                    >{aboutMe !== '' ? isTr ? aboutMe.text_t : aboutMe.text_e : ""}</span>
                </div>
            </div>
        </section>
    )
}
