import React, { useEffect, useState } from 'react'
import { getWork, addWork, updateWork, deleteWork } from '../../services/my_works';

export default function Work() {
    const [works, setWorks] = useState([])
    const [type, setType] = useState("")
    const [title_t, setTitle_t] = useState("")
    const [title_e, setTitle_e] = useState("")
    const [image, setImage] = useState("")
    const [url, setUrl] = useState("")
    const [aboutProject_t, setAboutProject_t] = useState("")
    const [aboutProject_e, setAboutProject_e] = useState("")
    useEffect(() => {
        getWork().then(res => {
            setWorks(res)
        })
    }, [])
    function addWorkFunction() {
        addWork(type, title_t, title_e, image, url, aboutProject_t, aboutProject_e).then(response => {
            getWork().then(res => {
                setWorks(res)
            })
        })
    }

    function deleteWorkFunction(id) {
        deleteWork(id).then(response => {
            getWork().then(res => {
                setWorks(res)
            })
        })
    }
    return (
        <div>
            {!localStorage.getItem("isAuth") ? "not logged in" : <div>
                <div>Work</div>
                <hr className="solid" />
                <input type="text" placeholder='type' onChange={(e) => setType(e.target.value)} />
                <input type="text" placeholder='title_t' onChange={(e) => setTitle_t(e.target.value)} />
                <input type="text" placeholder='title_e' onChange={(e) => setTitle_e(e.target.value)} />
                <input type="text" placeholder='image' onChange={(e) => setImage(e.target.value)} />
                <input type="text" placeholder='url' onChange={(e) => setUrl(e.target.value)} />
                <input type="text" placeholder='aboutProject_t' onChange={(e) => setAboutProject_t(e.target.value)} />
                <input type="text" placeholder='aboutProject_e' onChange={(e) => setAboutProject_e(e.target.value)} />
                <button onClick={() => addWorkFunction()}>Add</button>
                <hr className="solid" />
                {works.length > 0 ?
                    works.map((item, index) => {
                        return (<div key={index}>
                            <div>type : {item.type}</div>
                            <div>title_t : {item.title_t}</div>
                            <div>title_e : {item.title_e}</div>
                            <div>image : <img style={{ width: "50%" }} src={item.image} alt="no images" /></div>
                            <div>url : {item.url}</div>
                            <div>aboutProject_t : {item.aboutProject_t}</div>
                            <div>aboutProject_e : {item.aboutProject_e}</div>
                            <button onClick={() => deleteWorkFunction(item._id)}>Delete</button>
                            <hr className="solid" />
                        </div>)
                    })
                    : <div>No data</div>}
            </div>}
        </div>
    )
}
