import axios from "axios";
import { url } from "../utils/url";

export async function getAboutMe() {
  const response = await axios.get(url + "about");
  return response.data;
}

export async function addAboutMe(text_t, text_e) {
  const about = { text_t: text_t, text_e: text_e };
  const response = await axios.post(url + "about", about);
  // return response.data;
}
export async function updateAboutMe(id, text_t, text_e) {
  const about = { text_t: text_t, text_e: text_e };
  const response = await axios.put(url + "about" + `/${id}`, about);
  // return response.data;
}
export async function deleteAboutMe(id) {
  const response = await axios.delete(url + "about" + `/${id}`);
  // return response.data;
}
