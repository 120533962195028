import axios from 'axios';
import { url } from '../utils/url';

export async function getSkills() {
    const response = await axios.get(url + "skills");
    return response.data;
}

export async function addSkill(skill_t, skill_e, description_t, description_e) {
    const skill = {
        skill_t: skill_t,
        skill_e: skill_e,
        description_t: description_t,
        description_e: description_e
    };
    const response = await axios.post(url + "skills", skill);
    console.log(response.data);
}
export async function updateSkill(id, skill_t, skill_e, description_t, description_e) {
    const skill = {
        skill_t: skill_t,
        skill_e: skill_e,
        description_t: description_t,
        description_e: description_e
    };
    const response = await axios.put(url + "skills" + `/${id}`, skill);
    console.log(response.data);
}
export async function deleteSkill(id) {
    const response = await axios.delete(url + "skills" + `/${id}`);
    console.log(response.data);
}