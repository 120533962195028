import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

export default function ContactMe({ isTr }) {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_v03acuk",
        "template_msljl5n",
        form.current,
        "taSi3shqtyImsbE5w"
      )
      .then(
        (result) => {
          document.getElementById("name").value = "";
          document.getElementById("email").value = "";
          document.getElementById("subject").value = "";
          document.getElementById("message").value = "";
          alert(isTr ? "Mesajın gönderildi" : "Your message has been sent!");
        },
        (error) => {
          alert(error.text);
        }
      );
  };
  return (
    <section className="section contact-me" data-section="section5">
      <div className="container">
        <div className="section-heading">
          <h2>{isTr ? "Benimle İletişime Geç" : "Contact Me"}</h2>
          <div className="line-dec"></div>
          <span>
            {isTr
              ? "Benimle İletişime Geçmek için aşağıdaki formu doldurabilirsiniz. Geri dönüş almak için e-posta'nızı yazmayı unutmayın"
              : "Fill out form below to contact me. Don't forget to write your e-mail to get feedback"}
          </span>
        </div>
        <div className="row">
          <div className="right-content">
            <div className="container">
              <form id="contact" ref={form} onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-md-6">
                    <fieldset>
                      <input
                        name="from_name"
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder={isTr ? "İsmin..." : "Your name..."}
                        required=""
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-6">
                    <fieldset>
                      <input
                        name="reply_to"
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder={isTr ? "E-posta..." : "Your email..."}
                        required=""
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-12">
                    <fieldset>
                      <input
                        name="subject"
                        type="text"
                        className="form-control"
                        id="subject"
                        placeholder={isTr ? "Konu..." : "Subject..."}
                        required=""
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-12">
                    <fieldset>
                      <textarea
                        name="message"
                        rows="6"
                        className="form-control"
                        id="message"
                        placeholder={isTr ? "Mesajın..." : "Your message..."}
                        required=""
                      ></textarea>
                    </fieldset>
                  </div>
                  <div className="col-md-12">
                    <fieldset>
                      <button type="submit" id="form-submit" className="button">
                        {isTr ? "Mesaj Yolla" : "Send Message"}
                      </button>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
