import React, { useState, useEffect } from 'react'
import { getSkills } from '../services/skills'

export default function WhatGoodAt({ isTr }) {
    var icons = [
        "first-service-icon service-icon",
        "second-service-icon service-icon",
        "third-service-icon service-icon",
        "fourth-service-icon service-icon",
    ]
    const returnValue = (index) => {
        if (index >= icons.length) {
            return returnValue(index - icons.length)
        }
        return icons[index];
    }
    const [skills, setSkils] = useState([])
    useEffect(() => {
        getSkills().then(res => {
            setSkils(res)
        })
    }, [])
    return (
        <section className="section my-services" data-section="section2">
            <div className="container">
                <div className="section-heading">
                    <h2>{isTr ? "İyi olduğum yetkinlikler" : "What I’m good at"}</h2>
                    <div className="line-dec"></div>
                </div>
                <div className="row">
                    {skills.length > 0 ? skills.map((item, index) => {
                        return (<div className="col-md-6">
                            <div className="service-item" style={{height: "320px"}}>
                                <div className={returnValue(index)}></div>
                                <h4>{isTr ? item.skill_t : item.skill_e}</h4>
                                <p>
                                    {isTr ? item.description_t : item.description_e}
                                </p>
                            </div>
                        </div>)
                    }) : ""}
                </div>
            </div>
        </section>
    )
}

