import React, { useEffect, useState } from 'react'
import { getBlog } from '../services/blogs'

export default function Blogs({ isTr }) {
    const [blogs, setBlogs] = useState([])
    useEffect(() => {
        getBlog().then(res => {
            console.log(res)
            setBlogs(res)
        })
    }, [])
    return (
        <section className="section my-work" data-section="section4">
            <div className="container">
                <div className="section-heading">
                    <h2>{isTr ? "Bloglarım" : "Blogs"}</h2>
                    <div className="line-dec"></div>
                </div>
                {blogs.length > 0 ? blogs.map((blog, index) => {
                    var blog_image = blog.image.split("||")[0];
                    var blog_url = blog.image.split("||")[1];
                    return (
                        <div key={index} className="left-image-post" style={{ marginBottom: "20px" }}>
                            <div className="row">
                                {index % 2 === 0 ? <div className="col-md-6">
                                    <div className="left-image" style={{ marginTop: "10%" }}>
                                        <img src={blog_image} alt="" />
                                    </div>
                                </div> : ""}
                                <div className="col-md-6">
                                    <div className="right-text">
                                        <h4>{isTr ? blog.title_t : blog.title_e}</h4>
                                        <p>{isTr ? blog.description_t : blog.description_e}</p>
                                        <div className="white-button">
                                            <a className='hoverButton' style={{ 'color': '#a43f49', '--hover-color': 'white' }} href={blog_url} target="_blank">{isTr ? "Daha Fazla Oku" : "Read More"}</a>
                                        </div>
                                    </div>
                                </div>
                                {index % 2 !== 0 ? <div className="col-md-6">
                                    <div className="left-image" style={{ marginTop: "10%" }}>
                                        <img src={blog_image} alt="" />
                                    </div>
                                </div> : ""}
                            </div>
                        </div>
                    )
                }) : ""}
            </div>
        </section>
    )
}
