import axios from 'axios';
import { url } from '../utils/url';

export async function getSocial() {
    const response = await axios.get(url + "social");
    return response.data;
}

export async function addSocial(twitter, linkedin, github, stackoverflow, others) {
    const social = {
        twitter: twitter,
        linkedin: linkedin,
        github: github,
        stackoverflow: stackoverflow,
        others: others // array of others
    };
    const response = await axios.post(url + "social", social);
    console.log(response.data);
}
export async function updateSocial(id, twitter, linkedin, github, stackoverflow, others) {
    const social = {
        twitter: twitter,
        linkedin: linkedin,
        github: github,
        stackoverflow: stackoverflow,
        others: others // array of others
    };
    const response = await axios.put(url + "social" + `/${id}`, social);
    return response;
}
export async function deleteSocial(id) {
    const response = await axios.delete(url + "social" + `/${id}`);
    console.log(response.data);
}