const appendScript = async (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    document.body.appendChild(script);
}


export const appendAllScripts = () => {
    appendScript("https://code.jquery.com/jquery-3.5.1.js").then(() => {
        setTimeout(() => {
            appendScript("assets/js/custom.js");
            appendScript("assets/js/isotope.min.js");
            appendScript("assets/js/scroll.js");
        }, 1000);
    });
}