import React, { useEffect, useState } from 'react'
import { getWork } from '../../services/my_works'
import './my_work.css';
export default function MyWork({ isTr }) {
    const [works, setWorks] = useState([])
    const [selectedType, setSelectedType] = useState("all");
    useEffect(() => {
        getWork().then(res => {
            setWorks(res)
        })
    }, [])
    return (
        <section className="section my-work" data-section="section3">
            <div className="container">
                <div className="section-heading">
                    <h2>{isTr ? "Projelerim" : "My Work"}</h2>
                    <div className="line-dec"></div>
                </div>
                <div className="row">
                    <div className="isotope-wrapper">
                        <form className="isotope-toolbar">
                            <label
                            ><input
                                    type="radio"
                                    data-type="*"
                                    checked=""
                                    name="isotope-filter"
                                    onClick={() => setSelectedType("all")}
                                />
                                <span>{isTr ? "hepsi" : "all"}</span></label>
                            <label
                            ><input
                                    type="radio"
                                    data-type="people"
                                    name="isotope-filter"
                                    onClick={() => setSelectedType("mobile")}
                                />
                                <span>{isTr ? "mobil" : "mobile"}</span></label
                            >
                            <label
                            ><input
                                    type="radio"
                                    data-type="nature"
                                    name="isotope-filter"
                                    onClick={() => setSelectedType("web")}
                                />
                                <span>web</span></label
                            >
                            <label
                            ><input
                                    type="radio"
                                    data-type="nature"
                                    name="isotope-filter"
                                    onClick={() => setSelectedType("ai")}
                                />
                                <span>AI</span></label
                            >
                        </form>
                        {works.length > 0 ? works.filter(work => selectedType === "all" || work.type === selectedType).map(work => {
                            return (<div className="isotope-box-x">
                                <div className="isotope-item-x" data-type="nature">
                                    <figure className="snip1321-x">
                                        <img
                                            src={work.image}
                                            style={{ height: "250px" }}
                                            alt="sq-sample26"
                                        />
                                        <figcaption>
                                            {/* <a
                                                href={work.image}
                                                data-lightbox="image-1"
                                                data-title="Caption"
                                            ><i className="fa fa-search"></i></a> */}

                                            <h4>{isTr ? work.title_t : work.title_e}</h4>
                                            <span>{isTr ? work.aboutProject_t : work.aboutProject_e}</span>
                                            <div style={{ marginBottom: "20px" }}>
                                                <button><a className='hoverButton' style={{ 'color': '#a43f49', '--hover-color': 'white' }} href={work.url} target="_blank">{isTr ? "Daha Detaylı" : "More Detail"}</a></button>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </div>
                            </div>)
                        }) : ""}
                    </div>
                </div>
            </div>
        </section>
    )
}
