import React, { useEffect, useState } from 'react'
import { addAboutMe, getAboutMe, updateAboutMe } from '../../services/about_me';

export default function About() {
    const [about, setAbout] = useState([])
    const [text_t, setText_t] = useState("")
    const [text_e, setText_e] = useState("")
    useEffect(() => {
        getAboutMe().then(res => {
            setAbout(res)
            console.log(res)
        })
    }, [])
    function addAboutFunction() {
        addAboutMe(text_t, text_e).then(response => {
            getAboutMe().then(res => {
                setAbout(res)
            })
        })
    }
    function updateAboutFunction() {
        updateAboutMe(about[0]._id, text_t, text_e).then(response => {
            getAboutMe().then(res => {
                setAbout(res)
            })
        })
    }
    return (
        <div>
            {!localStorage.getItem("isAuth") ? "not logged in" : <div>
                <div>About</div>
                <hr className="solid" />
                <input type="text" placeholder='text_t' onChange={(e) => setText_t(e.target.value)} />
                <input type="text" placeholder='text_e' onChange={(e) => setText_e(e.target.value)} />
                <button onClick={() => addAboutFunction()}>Add</button>
                <hr className="solid" />
                <input type="text" placeholder='text_t' onChange={(e) => setText_t(e.target.value)} />
                <input type="text" placeholder='text_e' onChange={(e) => setText_e(e.target.value)} />
                <button onClick={() => updateAboutFunction()}>Update</button>
                <hr className="solid" />
                {about.length > 0 ?
                    about.map((item, index) => {
                        return (<div key={index}>
                            <div>text_t : {item.text_t}</div>
                            <div>text_e : {item.text_e}</div>
                            <hr className="solid" />
                        </div>)
                    })
                    : <div>No data</div>}
            </div>}
        </div>
    )
}
