import "./App.css";
import React, { useState, useEffect } from "react";
import { appendAllScripts } from "./utils/appendScript";
import NavBar from "./components/nav_bar";
import AboutMe from "./components/about_me";
import WhatGoodAt from "./components/what_good_at";
import MyWork from "./components/my_work/my_work";
import ContactMe from "./components/contact_me";
import Blogs from "./components/blogs";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Admin from "./components/admin/admin";
import Auth from "./components/auth";
import About from "./components/admin/about";
import Blog from "./components/admin/blog";
import Work from "./components/admin/work";
import Skill from "./components/admin/skill";
function App() {
  const [isTr, setIsTr] = useState(false);
  useEffect(() => {
    appendAllScripts();
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <div id="page-wraper">
              <NavBar isTr={isTr} setIsTr={setIsTr} />
              <AboutMe isTr={isTr} />
              <WhatGoodAt isTr={isTr} />
              <MyWork isTr={isTr} />
              <Blogs isTr={isTr} />
              <ContactMe isTr={isTr} />
            </div>
          }
        />
        <Route path="/auth" element={<Auth />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/about" element={<About />} />
        <Route path="/admin/blog" element={<Blog />} />
        <Route path="/admin/work" element={<Work />} />
        <Route path="/admin/skill" element={<Skill />} />
      </Routes>
    </Router>
  );
}

export default App;
