import React, { useEffect, useState } from 'react'
import { getSkills, addSkill, updateSkill, deleteSkill } from '../../services/skills';

export default function Skill() {
    const [skills, setSkills] = useState([])
    const [skill_t, setSkill_t] = useState("")
    const [skill_e, setSkill_e] = useState("")
    const [description_t, setDescription_t] = useState("")
    const [description_e, setDescription_e] = useState("")
    useEffect(() => {
        getSkills().then(res => {
            setSkills(res)
        })
    }, [])
    function addSkillFunction() {
        addSkill(skill_t, skill_e, description_t, description_e).then(response => {
            getSkills().then(res => {
                setSkills(res)
            })
        })
    }

    function deleteSkillFunction(id) {
        deleteSkill(id).then(response => {
            getSkills().then(res => {
                setSkills(res)
            })
        })
    }
    return (
        <div>
            {!localStorage.getItem("isAuth") ? "not logged in" : <div>
                <div>Skill</div>
                <hr className="solid" />
                <input type="text" placeholder='skill_t' onChange={(e) => setSkill_t(e.target.value)} />
                <input type="text" placeholder='skill_e' onChange={(e) => setSkill_e(e.target.value)} />
                <input type="text" placeholder='description_t' onChange={(e) => setDescription_t(e.target.value)} />
                <input type="text" placeholder='description_e' onChange={(e) => setDescription_e(e.target.value)} />
                <button onClick={() => addSkillFunction()}>Add</button>
                <hr className="solid" />
                {skills.length > 0 ?
                    skills.map((item, index) => {
                        return (<div key={index}>
                            <div>skill_t : {item.skill_t}</div>
                            <div>skill_e : {item.skill_e}</div>
                            <div>description_t : {item.description_t}</div>
                            <div>description_e : {item.description_e}</div>
                            <button onClick={() => deleteSkillFunction(item._id)}>Delete</button>
                            <hr className="solid" />
                        </div>)
                    })
                    : <div>No data</div>}
            </div>}
        </div>
    )
}
