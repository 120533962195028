import React, { useEffect, useState } from 'react'
import { getSocial, updateSocial } from '../../services/social'
export default function Admin() {
    const [social, setSocial] = useState([])
    const [twitter, setTwitter] = useState([])
    const [linkedin, setLinkedin] = useState([])
    const [github, setGithub] = useState([])
    const [stackoverflow, setStackOverFlow] = useState([])
    const [responseUpdateSocial, setResponseUpdateSocial] = useState()

    useEffect(() => {
        getSocial().then(res => {
            setSocial(res);
        })
    }, [])

    const updateSocialFunction = () => {
        updateSocial(social[0]._id, twitter, linkedin, github, stackoverflow).then(response => {
            setResponseUpdateSocial(response.data.message)
        })
    }
    return (
        <div>
            {!localStorage.getItem("isAuth") ? "not logged in" : <div>
                <button><a href='/admin/about'>About</a></button>
                <hr className="solid" />
                <button><a href='/admin/blog'>Blog</a></button>
                <hr className="solid" />
                <button><a href='/admin/work'>Work</a></button>
                <hr className="solid" />
                <button><a href='/admin/skill'>Skill</a></button>
                <hr className="solid" />
                <div>Social</div>
                <input type="text" placeholder='twitter' onChange={(e) => setTwitter(e.target.value)} />
                <input type="text" placeholder='linkedin' onChange={(e) => setLinkedin(e.target.value)} />
                <input type="text" placeholder='github' onChange={(e) => setGithub(e.target.value)} />
                <input type="text" placeholder='stackoverflow' onChange={(e) => setStackOverFlow(e.target.value)} />
                <button onClick={() => updateSocialFunction()}>Update</button>
                <div>{responseUpdateSocial !== null ? responseUpdateSocial : "gelmedi"}</div>
            </div>}
        </div>
    )
}
