import axios from 'axios';
import { url } from '../utils/url';

export async function getBlog() {
    const response = await axios.get(url + "blog");
    return response.data;
}

export async function addBlog(title_t, title_e, description_t, description_e, image, byWho, blog_url) {
    const blog = {
        title_t: title_t,
        title_e: title_e,
        description_t: description_t,
        description_e: description_e,
        image: image,
        url: blog_url
    };
    const response = await axios.post(url + "blog", blog);
    console.log(response.data);
}
export async function updateBlog(id, title_t, title_e, description_t, description_e, image, byWho, url) {
    const blog = {
        title_t: title_t,
        title_e: title_e,
        description_t: description_t,
        description_e: description_e,
        image: image,
        url: url
    };
    const response = await axios.put(url + "blog" + `/${id}`, blog);
    console.log(response.data);
}
export async function deleteBlog(id) {
    const response = await axios.delete(url + "blog" + `/${id}`);
    console.log(response.data);
}